const pages = new Map();

pages.set('home', { name: 'Home', path: '/', anchorable: true });
pages.set('project-dev', { name: 'Project : Devs', path: '/project-dev', anchorable: true });
pages.set('project-workflow', { name: 'Project : Workflows', path: '/project-workflow', anchorable: true });
pages.set('about', { name: 'About', path: '/about', anchorable: true });
pages.set('under construction', { name: 'Under Construction', path: '/wip', anchorable: false });

pages.set('unit-mix', { name: 'Unit Mix', path: '/unit-mix', anchorable: false })
pages.set('unit-finder', { name: 'Unit Finder', path: '/unit-finder', anchorable: false })
pages.set('notion', { name: 'notion', path: '/notion', anchorable: false })
pages.set('webpage', { name: 'webpage', path: '/webpage', anchorable: false })

pages.set('view-analysis', { name: 'View Analysis Tools', path: '/view-analysis', anchorable: false })
pages.set('area-calculation', { name: 'Area Calculation', path: '/area-calculation', anchorable: false })
pages.set('rapid-patterning', { name: 'Rapid Patterning', path: '/rapid-patterning', anchorable: false })

export default pages;