import './Projects.css';
import notion_img1 from '../assets/NotionDev_01.gif'

const Webpage = () => {
    return(
        
        <section class="container grid project-contents">
            <br></br>
            <br></br>
            <div class="project-contents">
                <h3> NOTION DATABASE FOR PROJECT SUPPORT MANAGEMENT </h3>
                <br></br>
                <div>
                    <img src={notion_img1}/>
                </div>
                <p class="project-caption"> HA Notion Pages </p>
                <div class="project-text"> 
                Supporting multiple projects simultaneously can be challenging. To streamline this, I developed a Notion system for the Design Technology team, enabling us to provide support more efficiently. 
                
                </div>
                <br></br>
            </div>
        </section>
    );
}

export default Webpage;